/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { createReducer, on } from '@ngrx/store';
import {
    directoryEffectsInitialized,
    fetchContactsSuccess,
    fetchDirectoryEntriesSuccess,
    fetchSpeedDialLayoutsSuccess,
    selectFolder
} from './directory.actions';
import { Contact } from '../model/contact';
import { Folder } from '../model/folder';
import { SpeedDial, SpeedDialLayout } from '../model/speed-dial';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface DirectoryState {
    initialized: boolean;
    contacts: ContactState;
    speedDials: SpeedDialState;
    speedDialLayouts: SpeedDialLayoutState;
    folders: FolderState;
    folderPath: Folder[];
    suggestedFolders: SuggestedFolderState;
    suggestedFolderPath: Folder[]
}

export interface ContactState extends EntityState<Contact> {
}

export interface SpeedDialState extends EntityState<SpeedDial> {
}

export interface FolderState extends EntityState<Folder> {
}

export interface SuggestedFolderState extends EntityState<Folder> {
}

export interface SpeedDialLayoutState extends EntityState<SpeedDialLayout> {
}

export const contactAdapter: EntityAdapter<Contact> = createEntityAdapter<Contact>({
    selectId: (contact: Contact) => contact.uuid
});

export const folderAdapter: EntityAdapter<Folder> = createEntityAdapter<Folder>({
    selectId: (folder: Folder) => folder.uuid
});

export const suggestedFolderAdapter = createEntityAdapter<Folder>({
    selectId: (folder: Folder) => folder.uuid
});

export const speedDialAdapter: EntityAdapter<SpeedDial> = createEntityAdapter<SpeedDial>({
    selectId: (speedDial: SpeedDial) => speedDial.uuid
});

export const speedDialLayoutAdapter = createEntityAdapter<SpeedDialLayout>({
    selectId: (speedDial) => speedDial.esn
});

const initialContactState: ContactState = contactAdapter.getInitialState({
});

const initialSpeedDialState: SpeedDialState = speedDialAdapter.getInitialState({
});

const initialFolderState: FolderState = folderAdapter.getInitialState({
});

const initialSuggestedFolderState = suggestedFolderAdapter.getInitialState();

const initialSpeedDialLayoutState: SpeedDialLayoutState = speedDialLayoutAdapter.getInitialState({
});

const initialState: DirectoryState = {
    initialized: false,
    contacts: initialContactState,
    speedDials: initialSpeedDialState,
    speedDialLayouts: initialSpeedDialLayoutState,
    folders: initialFolderState,
    folderPath: [],
    suggestedFolderPath: [],
    suggestedFolders: initialSuggestedFolderState
};

export const directoryReducer = createReducer(
    initialState,
    on(directoryEffectsInitialized, (state): DirectoryState => {
        return { ...state, initialized: true };
    }),
    on(fetchContactsSuccess, (state, { contacts }) => {
        return { ...state, contacts: contactAdapter.setAll(contacts, state.contacts) };
    }),
    on(fetchSpeedDialLayoutsSuccess, (state, { layouts }) => {
        const flatSpeedDials = layouts.flatMap((l) => l.speedDials);
        let folders = flatSpeedDials.filter((sd) => sd.type === 'FOLDER') as Folder[];
        let folderPath = folders[0] ? [folders[0]] : [];
        return { ...state,
            suggestedFolderPath: folderPath,
            suggestedFolders: suggestedFolderAdapter.setAll(folders, state.suggestedFolders),
            speedDialLayouts: speedDialLayoutAdapter.setAll(layouts, state.speedDialLayouts)
        };
    }),
    on(fetchDirectoryEntriesSuccess, (state, { entries }) => {
        let folders = entries.filter((entry) => entry.type === 'FOLDER') as Folder[];
        let speedDials = entries.filter((entry) => entry.type === 'BUTTON') as SpeedDial[];
        let folderPath = folders[0] ? [folders[0]] : [];
        return {
            ...state,
            folders: folderAdapter.setAll(folders, state.folders),
            speedDials: speedDialAdapter.setAll(speedDials, state.speedDials),
            folderPath: folderPath
        };
    }),
    on(selectFolder, (state, { folder, suggested }) => {
        let pathKey: keyof DirectoryState = suggested ? 'suggestedFolderPath' : 'folderPath';
        let path = [...state[pathKey]];
        let index = path.findIndex((item) => item.uuid == folder.uuid);
        // if selected folder is not part of the current path push to go 'down', otherwise if it's not the current folder slice to go 'up'.
        if (index == -1) {
            path.push(folder);
        } else if (index != path.length - 1) {
            path = path.slice(0, index + 1);
        }
        return { ...state, [pathKey]: path };
    }),
);

export const selectContactState = (state: DirectoryState) => state.contacts;
export const selectFolderState = (state: DirectoryState) => state.folders;
export const selectSuggestedFolderState = (state: DirectoryState) => state.suggestedFolders;
export const selectSpeedDialState = (state: DirectoryState) => state.speedDials;
export const selectSpeedDialLayoutState = (state: DirectoryState) => state.speedDialLayouts;

export const {
    selectAll: selectAllContacts,
    selectEntities: selectContactEntities
} = contactAdapter.getSelectors();
export const {
    selectAll: selectAllFolders,
    selectEntities: selectFolderEntities
} = folderAdapter.getSelectors();
export const {
    selectAll: selectAllSuggestedFolders,
    selectEntities: selectSuggestedFolderEntities
} = suggestedFolderAdapter.getSelectors();
export const {
    selectAll: selectAllSpeedDials,
    selectEntities: selectSpeedDialEntities
} = speedDialAdapter.getSelectors();
export const {
    selectAll: selectAllSpeedDialLayouts,
    selectEntities: selectSpeedDialLayoutEntities,
} = speedDialLayoutAdapter.getSelectors();
