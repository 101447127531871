/*
 * *****************************************************************************
 *  Copyright (C)  Motorola Solutions, INC.
 *  All Rights Reserved.
 *  Motorola Solutions Confidential Restricted.
 *  ******************************************************************************
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    DirectoryState,
    selectAllContacts,
    selectAllFolders,
    selectAllSpeedDials, selectAllSuggestedFolders,
    selectContactEntities,
    selectContactState,
    selectFolderEntities,
    selectFolderState,
    selectSpeedDialEntities, selectSpeedDialLayoutEntities, selectSpeedDialLayoutState,
    selectSpeedDialState, selectSuggestedFolderEntities, selectSuggestedFolderState
} from './directory.reducer';
import { DirectoryItem, DirectoryItemReference } from '../model/directory-item';
import { selectSuggestedSpeedDials } from '../../call/+state/call.selectors';

export const DIRECTORY_FEATURE = 'directory';

export const selectDirectoryState = createFeatureSelector<DirectoryState>(DIRECTORY_FEATURE);

export const selectDirectoryContactState = createSelector(selectDirectoryState, selectContactState);
export const selectDirectoryFolderState = createSelector(selectDirectoryState, selectFolderState);
export const selectDirectorySuggestedFolderState = createSelector(selectDirectoryState, selectSuggestedFolderState);
export const selectDirectorySpeedDialState = createSelector(selectDirectoryState, selectSpeedDialState);
export const selectDirectorySpeedDialLayoutState = createSelector(selectDirectoryState, selectSpeedDialLayoutState);

export const selectDirectoryEffectInitialized = createSelector(selectDirectoryState, (state) => state.initialized);

export const selectContacts = createSelector(selectDirectoryContactState, selectAllContacts);
export const selectFolders = createSelector(selectDirectoryFolderState, selectAllFolders);
export const selectSuggestedFolders = createSelector(selectDirectorySuggestedFolderState, selectAllSuggestedFolders);
export const selectSpeedDials = createSelector(selectDirectorySpeedDialState, selectAllSpeedDials);

export const selectContactsMap = createSelector(selectDirectoryContactState, selectContactEntities);
export const selectFoldersMap = createSelector(selectDirectoryFolderState, selectFolderEntities);
export const selectSuggestedFoldersMap = createSelector(selectDirectorySuggestedFolderState, selectSuggestedFolderEntities);
export const selectSpeedDialMap = createSelector(selectDirectorySpeedDialState, selectSpeedDialEntities);
export const selectSpeedDialLayoutMap = createSelector(selectDirectorySpeedDialLayoutState, selectSpeedDialLayoutEntities);
export const selectContact = (uuid: string) => createSelector(selectContactsMap, (contactsMap) => contactsMap[uuid]);
export const selectContactName = (uuid: string, placeholder: string) => createSelector(selectContact(uuid), (contact) => contact?.name ? contact.name : placeholder);
export const selectFolder = (uuid: string) => createSelector(selectFoldersMap, (foldersMap) => foldersMap[uuid]);
export const selectSpeedDial = (uuid: string) => createSelector(selectSpeedDialMap, (speedDialMap) => speedDialMap[uuid]);
export const selectSpeedDialName = (uuid: string, placeholder: string) => createSelector(selectSpeedDial(uuid), (speedDial) => speedDial?.name ? speedDial.name : placeholder);
export const selectSpeedDialIcon = (uuid: string, placeholder: string) => createSelector(selectSpeedDial(uuid), (speedDial) => speedDial?.icon ? speedDial.icon : placeholder);
export const selectDirectoryItems = (directoryItemReferences: DirectoryItemReference[]) => createSelector(selectFoldersMap, selectSpeedDialMap, (foldersMap, speedDialMap) =>
    directoryItemReferences.map((reference) => reference.type === 'FOLDER' ? foldersMap[reference.uuid] : speedDialMap[reference.uuid]) as DirectoryItem[]);

export const selectRootFolder = createSelector(selectFolders, (folders) => folders[0]);
export const selectSelectedFolderPath = createSelector(selectDirectoryState, (state) => state.folderPath);

export const selectSelectedFolder = createSelector(selectSelectedFolderPath, selectRootFolder, (selectedFolderPath, rootFolder) =>
    selectedFolderPath.length ? selectedFolderPath[selectedFolderPath.length -1] : rootFolder
);

export const selectSuggestedFolderPath = createSelector(
    selectDirectoryState,
    (state) => state.suggestedFolderPath
);

export const selectSuggestedRootFolder = createSelector(
    selectSuggestedFolders,
    (folders) => folders[0]
);

export const selectSuggestedFolder = createSelector(
    selectSuggestedFolderPath,
    selectSuggestedRootFolder,
    (folderPath, rootFolder) => folderPath.length ? folderPath[folderPath.length - 1] : rootFolder
);
